import styled from "styled-components"
import { Container } from "@mui/material"
import FormatQuoteIcon from "@mui/icons-material/FormatQuote"
import CustomImage from "../../components/custom-image/custom-image.component"
import quotes from "../../assets/quotes.svg"

export const Wrapper = styled(Container)`
  background-color: ${({ theme }) => theme.palette.secondary.light};
  padding: 2.5rem;
  position: relative;
  margin-bottom: 3rem;
  margin-top: 3rem;
`
export const ContentDiv = styled.div`
  margin-top: 1rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    column-gap: ${({ theme }) => theme.typography.pxToRem(70)};
  }
`
// export const Icon = styled(FormatQuoteIcon)`
//   color: ${({ theme }) => theme.palette.secondary.main};
//   position: absolute;
//   top: -30px;
//   left: -10px;
//   height: 77px;
//   width: 90px;
//   transform: rotate(180deg);
//   ${({ theme }) => theme.breakpoints.up("md")} {
//     top: -30px;
//     left: -30px;
//   }
// `
export const Icon = styled(quotes)`
  position: absolute;
  left: 20px;
  width: 50px;
  height: auto;
  top: -20px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    top: -30px;
    left: -30px;
    height: 77px;
    width: 90px;
  }
`
export const DescriptionDiv = styled.div``
export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text.content};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  font-weight: 500;
  &.caseDescription {
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.secondary};
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: ${({ theme }) => theme.typography.pxToRem(34)};
    line-height: ${({ theme }) => theme.typography.pxToRem(40)};
    font-weight: 300;
    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: 1.2rem;
      line-height: normal;
    }
  }
`
export const AuthorDiv = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1em;
  margin-top: ${({ theme }) => theme.typography.pxToRem(40)};
`
export const AuthorImage = styled(CustomImage)`
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
`
export const StatisticDiv = styled.div``
export const Percentage = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(68)};
  line-height: ${({ theme }) => theme.typography.pxToRem(72)};
  font-weight: 500;
`
export const PercentageDescription = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  font-weight: 500;
`
