import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import ArticleHero from "../../layouts/ArticleHero"
import ShareContent from "../../components/share-content/share-content.component"
import OtherPostGridPagination from "../../components/other-post-grid-pagination/other-post-grid-pagination.component"
import { Container } from "@mui/material"
import { getCaseStudyLayout } from "../../get-case-study-layout-utils"
import Highlights from "../../layouts/Highlights"

// , $categoryId: String!
export const query = graphql`
  query CaseQuery($id: String!) {
    wpCaseStudy(id: { eq: $id }) {
      id
      title
      content
      uri
      slug
      excerpt
      date(formatString: "MMMM DD, YYYY")
      caseStudyCategories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      caseStudyBuilder {
        layouts {
          ... on WpCaseStudy_Casestudybuilder_Layouts_MiscContentPost {
            ...MiscContentCaseStudy
          }
          ... on WpCaseStudy_Casestudybuilder_Layouts_ImageContentPost {
            ...ImageContentCaseStudy
          }
          ... on WpCaseStudy_Casestudybuilder_Layouts_StatisticBox {
            ...StatisticBox
          }
          ... on WpCaseStudy_Casestudybuilder_Layouts_CaseStudyCarrousel {
            ...CaseStudyCarrousel
          }
          ... on WpCaseStudy_Casestudybuilder_Layouts_TestimonialsSlider {
            ...TestimonialsSlider
          }
        }
        pageConfiguration {
          hideFooter
        }
        logo {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        highlights {
          title
          subtitle
          description
          items {
            description
            title
          }
        }
      }
    }
    allWpCaseStudy(limit: 3, filter: { id: { nin: [$id] } }) {
      nodes {
        id
        uri
        title
        caseStudyCategories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
const CaseStudyTemplate = ({ data }) => {
  const { seo, caseStudyBuilder, title, featuredImage, uri, excerpt } =
    data.wpCaseStudy

  const layouts = caseStudyBuilder?.layouts || []
  return (
    <Layout {...caseStudyBuilder?.pageConfiguration} seo={seo} title={title}>
      <ArticleHero
        logoImage={caseStudyBuilder?.logo}
        image={featuredImage?.node}
        title={excerpt}
        category="CASE STUDY"
      />
      {layouts.map(layout => getCaseStudyLayout(layout))}
      <Highlights {...caseStudyBuilder?.highlights} />
      <ShareContent
        description={"Like this case? Share it"}
        postUrl={`/resources${uri}`}
        // haveVideo={false}
      />
      <Container maxWidth="xl">
        <OtherPostGridPagination
          titleSection={"Related Cases"}
          articles={data.allWpCaseStudy?.nodes}
          postPerPage={2}
        />
      </Container>
    </Layout>
  )
}

export default CaseStudyTemplate
