import React, { useRef } from "react"
import * as S from "./testimonials-slider.styles"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Container } from "@mui/material"
import TestimonialCard from "../../components/testimonial-card/testimonial-card.component"
import { ArrowBack, ArrowForward } from "@mui/icons-material"
const TestimonialsSlider = ({ testimonials }) => {
    const slider = useRef()

    const next = () => {
        slider.current.slickNext()
    }

    const previous = () => {
        slider.current.slickPrev()
    }

    const settings = {
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        dots: true,
        adaptiveHeight: true,
    }

    return (
        <S.Wrapper>
            <Container>
                <S.SliderWrapper>
                    <Slider ref={slider} {...settings}>
                        {testimonials?.map(
                            ({ description, author, company, role }, index) => (
                                <TestimonialCard
                                    key={`testimonial-card-${index}`}
                                    description={description}
                                    author={author}
                                    company={company}
                                    role={role}
                                />
                            )
                        )}
                    </Slider>
                </S.SliderWrapper>
                <S.ArrowBackIcon onClick={previous}>
                    <ArrowBack />
                </S.ArrowBackIcon>
                <S.ArrowNextIcon onClick={next}>
                    <ArrowForward />
                </S.ArrowNextIcon>
            </Container>
        </S.Wrapper>
    )
}

export default TestimonialsSlider