import React from "react"

import * as S from "./statistic-box.styles"

const StatisticBox = ({
  containerMaxWidth,
  caseDescription,
  authorImage,
  percentageDescription,
  companyName,
  percentage,
  name,
  role,
}) => {
  return (
    <S.Wrapper maxWidth={containerMaxWidth}>
      <S.Icon />
      <S.ContentDiv>
        <S.DescriptionDiv>
          {caseDescription && (
            <S.Description className="caseDescription">
              {caseDescription}
            </S.Description>
          )}
          {(authorImage || name || role) && (
            <S.AuthorDiv>
              {authorImage && <S.AuthorImage img={authorImage} alt="author" />}
              {name && role && (
                <S.Description>
                  {name}
                  <br />
                  {role}
                </S.Description>
              )}
            </S.AuthorDiv>
          )}
        </S.DescriptionDiv>
        <S.StatisticDiv>
          <S.Percentage>{percentage}</S.Percentage>
          <S.PercentageDescription>
            {percentageDescription}
          </S.PercentageDescription>
          <S.Description>{companyName}</S.Description>
        </S.StatisticDiv>
      </S.ContentDiv>
    </S.Wrapper>
  )
}

export default StatisticBox
