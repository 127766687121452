import React from "react"
import MiscContentPost from "./layouts/MiscContentPost"
import ImageContentPost from "./layouts/ImageContentPost"
import StatisticBox from "./layouts/StatisticBox"
import CaseStudyCarrousel from "./layouts/CaseStudyCarrousel"
import TestimonialsSlider from "./layouts/TestimonialsSlider";

export const getCaseStudyLayout = layout => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContentPost":
      return <MiscContentPost {...layout} />
    case "ImageContentPost":
      return <ImageContentPost {...layout} />
    case "StatisticBox":
      return <StatisticBox {...layout} />
    case "CaseStudyCarrousel":
      return <CaseStudyCarrousel {...layout} />
    case "TestimonialsSlider":
      return <TestimonialsSlider {...layout} />
  }
}
