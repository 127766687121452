import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import * as S from "./case-study-carrousel.styles"

const CaseStudyCarrousel = ({ images }) => {
  const settings = {
    infinite: true,
    slidesToShow: images?.length < 3 ? images?.length : 3,
    slidesToScroll: 1,
    speed: 600,
    autoplay: true,
    focusOnSelect: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 930,
        settings: {
          slidesToShow: images?.length >= 3 ? 3 : images?.length,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  if (!images) return null
  return (
    <S.Section>
      <S.CustomSlider {...settings}>
        {images.map(({ image }, index) => (
          <S.ImageDiv key={index}>
            <S.Image img={image} alt={index} />
          </S.ImageDiv>
        ))}
      </S.CustomSlider>
    </S.Section>
  )
}

export default CaseStudyCarrousel
