import styled from "styled-components"
import Slider from "react-slick"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Section = styled(SectionWrapper)``
export const CustomSlider = styled(Slider)`
  width: 100%;
  .slick-list {
    //overflow: unset;
  }
  .slick-slide {
    //opacity: 0.3;
    //transition: 0.5s ease all;

    &.slick-active {
      //display: block;
      ////transform: scale(1.2);
      ////opacity: 1;
      ////position: relative;
      ////z-index: 2;
    }
  }
  .slick-slide > div {
    margin: 0 5px;
  }
`
export const ImageDiv = styled.div`
  width: 100%;
`
export const Image = styled(CustomImage)`
  width: 100%;
  height: 504px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 250px;
  }
`
